<template>
	<div class="info">
		<div class="left">
			<div class="pic">
				<div class="name">{{ name }}</div>
				<img :src="avatar" class="ava" />
				<!-- <el-button type="primary" size="medium">修改照片</el-button> -->
			</div>
			<div class="category">
				<div class="inner">
					<div
						:class="['line', { active: $route.path == '/operation/basics' }]"
						@click="jump('/operation/basics?id=' + id + '&name=' + name)"
					>
						<i class="iconfont icon_doc"></i>
						<!-- <img src="../../assets/img/list_icon02.png" /> -->
						基础信息
					</div>
					<div
						:class="['line', { active: $route.path == '/pdworld/pdstore' }]"
						@click="jump('/pdworld/pdstore?id=' + id + '&name=' + name)"
						v-if="$tools.hasPermission('pdworld:pdStore:pageList')"
					>
						<i class="iconfont icon_cspace"></i>
						商家网点
					</div>
					<div
						:class="['line', { active: $route.path == '/pdworld/pdorder' }]"
						@click="jump('/pdworld/pdorder?id=' + id + '&name=' + name)"
						v-if="$tools.hasPermission('pdworld:pdOrder:pageList')"
					>
						<i class="iconfont icon_file"></i>
						预约订单
					</div>
					<div
						:class="['line', { active: $route.path == '/pdworld/pdqa' }]"
						@click="jump('/pdworld/pdqa?id=' + id + '&name=' + name)"
						v-if="$tools.hasPermission('pdworld:pdQa:pageList')"
					>
						<i class="iconfont icon_dingtalk_line"></i>
						商家问答
					</div>
					<div
						:class="['line', { active: $route.path == '/pdworld/pdshopinfoimg' }]"
						@click="jump('/pdworld/pdshopinfoimg?id=' + id + '&name=' + name)"
						v-if="$tools.hasPermission('pdworld:pdShopinfoImg:pageList')"
					>
						<i class="iconfont icon_medal"></i>
						商家图片
					</div>
					<div
						:class="['line', { active: $route.path == '/pdworld/pdline' }]"
						@click="jump('/pdworld/pdline?id=' + id + '&name=' + name)"
						v-if="$tools.hasPermission('pdworld:pdRoute:pageList')"
					>
						<i class="iconfont icon_exchange"></i>
						商家线路
					</div>
				</div>
			</div>
		</div>
		<!--  part1-->
		<div class="right">
			<router-view :key="$route.path"></router-view>
		</div>

		<!--求职意向的 新增、编辑 -->
		<!-- <form-edit v-if="formEditVisible" ref="formEdit" @refreshData="queryList"></form-edit> -->
	</div>
</template>

<script>
// import { dictList, } from "@/api/public.api.js";
// // import FortressPagination from "@/components/fortress-pagination.vue";
// import { hrjobintentionPage } from "@/api/hr/hrjobintention.js";
export default {
	name: "pinfo",
	data() {
		return {
			avatar: "",
			id: "",
			name: "",
		}
	},
	components: {
		// pagination: FortressPagination,
	},
	// activated () {
	//   let url = this.$route.query
	//   if (url) {
	//     console.log(url);
	//     this.init(url.id)
	//   }
	// },
	activated() {},
	created() {
		this.avatar = localStorage.getItem("memberAvatar")
		this.name = this.$route.query.name
		this.id = this.$route.query.id
	},
	methods: {
		jump(path) {
			this.$router.push(path)
		},
	},
}
</script>

<style scoped lang="scss">
.info {
	display: flex;
	padding: 20px;
	border-radius: 4px;
	background: #fff;
	/* min-width: 320px; */
	overflow-x: hidden;
	.left {
		width: 16%;
		height: 100%;
		border-right: 1px solid #eee;
		.pic {
			height: 240px;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			.name {
				padding: 0 15px;
				font-size: 16px;
				margin-bottom: 10px;
				text-align: center;
			}
			img {
				width: 120px;
				height: 120px;
				border-radius: 0px;
				object-fit: cover;
				border: 2px solid #eee;
				border-radius: 5px;
			}
		}
		.category {
			height: calc(100% - 300px);
			overflow: auto;
			.inner {
				.line {
					display: flex;
					align-items: center;
					padding: 0 30px 0 10px;
					height: 60px;
					color: #333;
					border-radius: 2px;
					font-size: 14px;
					cursor: pointer;
					i {
						margin-right: 8px;
						font-size: 16px;
					}
					&:hover {
						// background: #f5f7fa;
						color: #0cafcc;
					}
				}
			}
			.active {
				background: #f5f7fa;
				color: #0ec9eb !important;
				&:hover {
					background: #f5f7fa;
					color: #0cafcc;
				}
			}
		}
	}
	.right {
		padding: 0 10px 0 30px;
		width: 84%;
	}
}
</style>
